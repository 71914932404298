<template>
  <div>
    <BaseContainer class="pt-24 pb-16 relative z-20 min-h-screen">
      <BaseHeadingAndText heading="Story" headingSize="6xl" headingColor="cdlpink" textSize="xl" >
        Lonely. Abandoned. Beaten. Sick.
      </BaseHeadingAndText>

      <BaseHeadingAndText>
        That was Zorro before he committed himself to fight on behalf of all his fellow doggos that don't have a beautiful life...yet.
      </BaseHeadingAndText>

      <BaseHeadingAndText>
        Zorro chose 3,000 doggos from 8 countries all around the world that are looking for you to adopt them and make their life a better one!
      </BaseHeadingAndText>

      <BaseHeadingAndText>
        Some of them come from shelters where there aren't enough kind-hearted souls to adopt them. Others are sick with no financial resources to pay for their medical care.
      </BaseHeadingAndText>

      <BaseHeadingAndText>
        This is where your journey begins. We care, so we share. And we know you do too. Because in doggos we trust.
      </BaseHeadingAndText>

      <BaseHeadingAndText>
        The key is a joint effort by all of us. Become part of Cuddle Dogs, and help Zorro fulfill his mission by adopting a doggo NFT, which will be released as a limited edition of 3000 unique works of cuddly art.
      </BaseHeadingAndText>

      <BaseHeadingAndText>
        Let's leave no doggo behind without a caring and loving owner like you!!
      </BaseHeadingAndText>

      <BaseHeadingAndText>
        Zorro believes every single one of us can become an active part of the project. The first Cuddle Dog series contains dogs with different nationalities, breeds, traits and features, but also a variety of personalities and clothing – every single Doggo is original – just like in real life.
      </BaseHeadingAndText>

      <BaseHeadingAndText>
        There is more on this long journey. We will design more collections that will fuel real-world impact, which we will of course share with our growing community in the future. 
      </BaseHeadingAndText>

      <BaseHeadingAndText>
        Zorro decided that over 50% of the funds must be used to support impact-driving projects & causes. We're working directly with organizations, shelters and vets taking care of sick and abandoned animals, while bringing them into this one-of-a-kind NFT adventure. 
      </BaseHeadingAndText>

      <BaseHeadingAndText>
        On top of that, we're identifying projects focused on innovative medical research that can have a life-changing impact (literally) on fatal diseases such as FIP in cats and cancer – a leading killer of dogs, cats and humans globally.
      </BaseHeadingAndText>

      <BaseHeadingAndText>
        Let's make these doggies smile again. Because even with NFTs, we can.
      </BaseHeadingAndText>
    </BaseContainer>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/common/Footer"
export default {
  name: "Story",
  components: {
    Footer
  },
  data() {
    return {}
  },
}
</script>
